import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/_global/layout/Layout";
import PageHeader from "../components/_global/layout/PageHeader";
import localize from "../components/_global/localization/localize";
import GalleryItem from "../components/_global/GalleryItem";
import styled from "styled-components";
import Section from "../components/_global/layout/Section";
import Spacer from "../components/_global/layout/Spacer";

const ItemsContainer = styled.div`
    display: grid;
    gap: 2.5rem 1.125rem ;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    grid-template-columns: repeat(1, 1fr);
    }
`

const Gallery = ({ data, ...props }) => {
    const { gallery, photos: { nodes: photos } } = data;
    // console.log(data)
    const images = photos.map((item, index) => {
        if (item.images?.[0].image.asset) {
            return (
                <GalleryItem key={index} data={item} />
            )
        }
        else {
            return null
        }
    })

    return (
        <Layout title={gallery.common.title}>
            <PageHeader title={gallery.common.title} />
            <Spacer s='medium' />

            <Section m="medium">
                <ItemsContainer>
                    {images}
                </ItemsContainer>
            </Section>
            <Spacer s='large' />
            <Spacer s='large' />
        </Layout>
    );
};

export default localize(Gallery);

export const query = graphql`
    {
        gallery: sanityGallery {
            common {
                link
                title {
                    _type
                    fr
                }
            }
            seeMoreButton {
              _type
              fr
            }
        }
        photos: allSanityPhoto(sort: {fields: date, order: DESC}) {
            nodes {
                _id
              description {
                _type
                fr
              }
              date
              images {
                ...ImageFragment
              }
            }
        }
    }
`;
